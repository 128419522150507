import { borderRadius, color } from "../../../style/constants";
import styled from "styled-components";

const SelectStylingDiv = styled.div`
  margin-bottom: 1rem;

  .wrapped {
    display: flex;
  }
`;

const LabelInputStyling = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 1rem;
    margin-right: 1rem;
    border-radius: 3px;
  }
  label {
    margin-right: 0 1rem;
    width: 10rem;
  }
`;

const Button = styled.button`
  background: ${color.mainBlue};
  color: ${color.mainWhite};
  font-size: 1.25rem;
  border: none;
  border-radius: ${borderRadius.smallRadius};
  padding: 1rem;
  width: 100%;
`;

export { SelectStylingDiv, LabelInputStyling, Button };