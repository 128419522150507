import React, { useEffect } from 'react'
import BaseLayout from '../../layouts/BaseLayout'
import TextField from '../contact-page/style/TextField'
import SubmitButton from '../../components/buttons/SubmitButton/SubmitButton';
import { FormStyle, StyledInput, StyledLabel } from './style'
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@apollo/client';
import { GET_CREDENTIALS } from '../../graphql/users';

const Login = () => {

  const { loading, error, data } = useQuery(GET_CREDENTIALS);

  const validate = Yup.object({
    email: Yup.string().email('Email is invalid').required('This field is required'),
    password: Yup.string().required('This field is required'),

  })

  return (
    <BaseLayout>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={(values, actions) => {
          console.log(actions.setSubmitting)
          data.getCredentials.map(c => {
            if (c.email === values.email && c.password === values.password) {
              console.log(`${c.email} compare with: ${values.email}. passwords: ${c.password} compare with: ${values.password}`)
            } else {
              console.log(false)
            }
          })
        }}
        validationSchema={validate}
      >
        {(props: FormikProps<any>) => (
          <Form style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '800px', margin: '5rem auto' }}>
            <Field>
              {({ field, form }) => (
                <TextField label="email" name="email" type="email" />
              )}
            </Field>
            <Field>
              {({ field, form }) => (
                <TextField label="password" name="password" type="password" />
              )}
            </Field>
            <SubmitButton>login</SubmitButton>
          </Form>
        )}
      </Formik>
    </BaseLayout>

  )
}

export default Login
