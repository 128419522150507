import React from 'react'
import Details from './Details'
import OrderTemplate from './OrderTemplate'

const Orders = () => {
  return (
    <>
      <OrderTemplate />
      <Details />
    </>
  )
}

export default Orders
