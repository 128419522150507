import { Formik } from 'formik'
import React from 'react'
import { BaseLayout } from '../../layouts'
import * as Yup from 'yup';
import { FormFlex } from '../checkout/style';
import CheckoutForm from '../checkout/CheckoutForm';
import SubmitButton from '../../components/buttons/SubmitButton/SubmitButton';
import { StyledForm } from './style';

const ProfileEdit = () => {

  const validate = Yup.object({
    street: Yup.string().required('This field is required'),
    number: Yup.string().required('This field is required'),
    city: Yup.string().required('This field is required'),
    zip: Yup.string().required('This field is required'),
    companyName: Yup.string().required('This field is required'),
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    email: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().required('This field is required'),
  })
  return (
    <BaseLayout>
      <h1>Edit profile</h1>
      
      <Formik
        initialValues={{
          street: '',
          number: '',
          city: '',
          zip: '',
          companyName: '',
          notes: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: ''
        }}
        validationSchema={validate}
        onSubmit={() => console.log("test")}
      >
        {
          formik => (
            <StyledForm>
              <div>
                <CheckoutForm type="email" name="email" label="Email" />
                <CheckoutForm type="password" name="password" label="password" />
                <CheckoutForm type="password" name="confirmPassword" label="confirm password" />

                <FormFlex>
                  <CheckoutForm type="text" name="street" label="Street" />
                  <CheckoutForm type="text" name="number" label="Nr" />
                </FormFlex>

                <FormFlex>
                  <CheckoutForm type="text" name="city" label="City" />
                  <CheckoutForm type="text" name="zip" label="Zip" />
                </FormFlex>
                <CheckoutForm type="text" name="phone" label="phone" />

              </div>
              <SubmitButton>Checkout</SubmitButton>
            </StyledForm>
          )
        }
      </Formik>
    </BaseLayout>
  )
}

export default ProfileEdit
