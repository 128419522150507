import React from 'react'
import { TagStyling } from './style'

const Tag = (props) => {
  return (
    <TagStyling>
      {props.tagname}
    </TagStyling>
  )
}

export default Tag
