import { color, borderRadius, screenSize } from "../../../style/constants";
import styled from "styled-components";

const H2 = styled.h2`
  margin: 2rem 0;
`;

const H3 = styled.h3`
  margin: 2rem 0;
`;



const Table = styled.table`
width: 100%;
font-size: 1.05rem;

tr {

  display: flex;

  :nth-child(4) {
    margin-top: 2rem;
  }

  th {
    background: ${color.secondaryBlue};
    color: ${color.mainWhite};
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    padding: 1rem;

    :nth-child(2) {
      text-align: center;
    }
    :nth-child(3) {
      text-align: right;
    }
  }

  td {
    width: 100%;
    margin: 0.5rem 0;

    :nth-child(2) {
      text-align: center;
    }

    :nth-child(3) {
      text-align: right;
    }
  }
}
`;

const TotalTr = styled.tr`
  background: ${color.secondaryBlue};
  color: ${color.mainWhite};
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
`;

const HeaderDetails = styled.span`
  background: ${color.secondaryBlue};
  color: ${color.mainWhite};
  font-size: 1.25rem;
  padding: 1rem;
  margin-top: 0.5rem;
  display: block;
`;

const P = styled.p`
  margin: 0.5rem 0;
`;

const StatusStyling = styled.div`
  margin-top: 2rem;

  display: flex;
  flex-direction: column;


  select {
    background: none;
    text-align: center;
    padding: 0.7rem;
    margin: 0.5rem 0;
    font-size: 1.25rem;
    border: 1px solid ${color.secondaryBlue};
    border-radius: ${borderRadius.smallRadius};

    @media (min-width: ${screenSize.tabletSize}) {
      height: 2.5rem;
      width: 7rem;
      padding: 0;
    }
  }

  button {
    background: ${color.mainOrange};
    color: ${color.mainWhite};
    text-align: center;
    font-size: 1.25rem;
    border: none;
    border-radius: ${borderRadius.smallRadius};
    padding: 0.7rem;
    margin-bottom: 1rem;

    @media (min-width: ${screenSize.tabletSize}) {
      border-radius: ${borderRadius.largeRadius};
      padding: 0.5rem 2rem;
      margin-left: 1rem;
      margin-top: 1rem;
    }
  }

  @media (min-width: ${screenSize.tabletSize}) {
    flex-direction: row;
    justify-content: space-between;
}
`;

const HeaderStatus = styled.h3`

`;

const ChangeStatusStyle = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screenSize.tabletSize}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
`;




export { H2, H3, Table, TotalTr, HeaderDetails, HeaderStatus, P, StatusStyling, ChangeStatusStyle };