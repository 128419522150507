import { screenSize } from "../../../style/constants";
import styled from "styled-components";

const StyledForm = styled.form`
  width: 100%;

  input {
    width: 100%;
  }

  button {
    margin-bottom: 1rem;
  }

  @media (min-width: ${screenSize.desktopSize}) {
    max-width: 800px;
  margin: 0 auto;
  }

`;


export { StyledForm };