import React from 'react';
import { Footer, Header } from '../components/layout';
import { GlobalStyling } from '../style/GlobalStyle';

const SecondaryLayout = ({ children }) => {
  return (
    <>
      <GlobalStyling />
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default SecondaryLayout;
