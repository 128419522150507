import React from 'react'
import phoneImg from '../../assets/img/phone.png'
import { H1, PhoneAdStyle, LinkSpan } from './style'

const AppAd = () => {
  return (
    <PhoneAdStyle>
      <H1>Check out our phone app <LinkSpan to="/">here</LinkSpan>!</H1>
      <img src={phoneImg} alt="" />
    </PhoneAdStyle>
  )
}

export default AppAd
