import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { BaseLayout } from '../../layouts';
import { ACCOUNTORDERS } from '../../routes';
import { OrdersContext } from '../productDetail/OrderDetailContext';
import { ShoppingCartLayout } from './style';

const ShoppingCart = () => {

  const [orders, setorders] = useContext(OrdersContext);

  console.log(orders)

  return (
    <BaseLayout>
      <ShoppingCartLayout>
        <h2>shopping cart</h2>
        {
          orders.map(order => {
            return <div className="meal">
              <span className="meal__mealname">{order.meal}</span>
              <span className="meal__mealprice">€{order.price}</span>
              <input onChange={(e) => console.log(e.target.value)} min={0} max={order.amount} value={order.amount} type="number" className="meal__mealamount" />
            </div>;
          })
        }
        <Link style={{ background: '#2F4858', color: 'white', padding: '0.5rem 1rem', display: 'inline-block', borderRadius: '50px', marginBottom: '1rem', marginTop: '1rem' }} to='../../pages/checkout' >To checkout</Link>
      </ShoppingCartLayout>
    </BaseLayout>
  )
}

export default ShoppingCart
