import React, { useContext } from 'react'
import MealCard from './MealCard'
import { HeaderTitle } from './style'


const AvailableMeals = () => {

  return (
    <div>
      <HeaderTitle>Available meals</HeaderTitle>
      <MealCard mealSize="small" />
      <MealCard mealSize="medium" />
      <MealCard mealSize="large" />
    </div>
  )
}

export default AvailableMeals
