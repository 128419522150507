import React from 'react'
import { useField, ErrorMessage } from 'formik'
import { StyledLabel, StyledInput, ErrorMessageStyle } from '../contact-page/style';
import { StyledFormDiv } from './style';


const CheckoutForm = ( { label, ...props } ) => {
    const [field, meta] = useField(props);

    return (
        <StyledFormDiv>
            <StyledLabel htmlFor={field.name}>{label}</StyledLabel>
            <StyledInput {...field} {...props}/>
            <ErrorMessageStyle><ErrorMessage name={field.name} /></ErrorMessageStyle>
        </StyledFormDiv>
    )
}

export default CheckoutForm
