import { color, screenSize, borderRadius } from "../../../../style/constants";
import styled from "styled-components";

const StyledFooter = styled.footer`
    background-color: ${color.mainOrange};
    color: ${color.mainWhite};
    padding-top: 1rem;
    padding-bottom: 2rem;
    text-align: center;

    h3 {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    @media (min-width: ${screenSize.tabletSize}) {
        text-align: left;
    }

    span {
        margin-top: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }
`

const FooterFlex = styled.div`
    @media (min-width: ${screenSize.tabletSize}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 3rem;
    }
`

const SocialUl = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: ${screenSize.tabletSize}) {
        justify-content: left;
    }

    li {
        margin-right: 0.5rem;
        transition: 0.2s ease;

        &:hover {
            filter: brightness(80%);
        }
    }
`

const NewsForm = styled.form`
    margin-bottom: 1rem;

    input {
        border: none;
        height: 2.5rem;
        border-radius: ${borderRadius.largeRadius};
        color: black;
        padding: 0 1rem;
        width: 100%;
    }
`

const NewsDiv = styled.div`
    width: 15rem;
    max-width: 100%;
    margin: 0 auto;
    position: relative;

    @media (min-width: ${screenSize.tabletSize}) {
        margin: inherit;
        width: 20rem;
    }

    button {
        margin-top: 0.5rem;
        width: 15rem;
        cursor: pointer;

        @media (min-width: ${screenSize.tabletSize}) {
            position: absolute;
            width: 7rem;
            right: 0.15rem;
            top: 0.15rem;
            margin-top: 0;
            padding: 0.55rem;
        }
    }
`

export {
    StyledFooter,
    FooterFlex,
    SocialUl,
    NewsForm,
    NewsDiv
}