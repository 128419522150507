export const LANDING = '/';
export const CONTACT = '/pages/contact-page';
export const ABOUT = '/pages/about-us';
export const SEARCH = '/pages/searchpage';
export const RESTAURANTAVAILABLE = '/pages/restaurantAvailable/:id';
export const RESTAURANTORDERS = '/pages/restaurantOrders';
export const ORDERPLACED = '/pages/order-placed';
export const SHOPPINGCART = '/pages/shoppingCart';
export const SIGNUP = '/pages/signup';
export const LOGIN = '/pages/login';
export const ACCOUNTOVERVIEW = '/pages/accountOverview';
export const PRODUCTDETAIL = '/pages/productDetail/:id';
export const CHECKOUT = '/pages/checkout';
export const EDITPROFILE = '/pages/..';
export const ACCOUNTFAVORITES = '/pages/..';
export const ACCOUNTORDERS= '/pages/..';
export const FAQ = '/pages/faq';