import { color } from "../../../style/constants";
import styled from "styled-components";

const FaqStyled = styled.div`
    margin-top: 3rem;
    margin-bottom: 3rem;

    h3 {
        color: ${color.mainOrange};
        margin-bottom: 1rem;
    }

    h2 {
        margin-bottom: 3rem;
    }

    div {
        margin-bottom: 2rem;
        max-width: 50rem;
    }

    li {
        list-style: inside;
        list-style-type: numeric;
    }
`;

export {
    FaqStyled
}