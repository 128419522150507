import React from 'react'
import { Link } from 'react-router-dom'
import * as Routes from '../../routes';
import { ProfileNavigationStyling } from './style';

const ProfileNavigation = () => {
  return (
    <ProfileNavigationStyling>
      <li>
        <Link to={Routes.EDITPROFILE}>Edit profile</Link>
      </li>
      <li>
        <Link to={Routes.ACCOUNTOVERVIEW}>Overview</Link>
      </li>
      <li>
        <Link to={Routes.ACCOUNTFAVORITES}>Favorites</Link>
      </li>
      <li>
        <Link to={Routes.ACCOUNTORDERS}>Orders</Link>
      </li>
    </ProfileNavigationStyling>
  )
}

export default ProfileNavigation
