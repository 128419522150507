import styled from "styled-components";
import { defaultPadding, screenSize, color } from "../../../../style/constants";
import backgroundImage from '../../../../assets/img/splash-image.jpg';

const HeaderStyle = styled.header`
  background-color: ${({ currentPage }) => currentPage ==='/pages/contact-page' || currentPage ==='/pages/about-us' || currentPage ==='/pages/order-placed' || currentPage ==='/pages/faq' || currentPage ==='/pages/checkout' ? `${color.mainBlue}` : 'none' };
  color: ${color.mainWhite};
  background: ${({ currentPage }) => currentPage === '/' ? `linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.4), rgba(0,0,0,0.2)), url(${backgroundImage})` : `${color.mainBlue}`};
  background-repeat: ${({ currentPage }) => currentPage === '/' ? `no-repeat` : 'inherit'};
  background-size: cover;
  height: ${({ currentPage }) => currentPage === '/' ? `100vh` : 'inherit'};

  max-width: 1920px;
    width: 100%;
    margin: 0 auto;

    padding-left: ${defaultPadding.mobilePadding};
    padding-right: ${defaultPadding.mobilePadding};

    @media (min-width: ${screenSize.tabletSize}) {
        padding-left: ${defaultPadding.tabletPadding};
        padding-right: ${defaultPadding.tabletPadding};
    }

    @media (min-width: ${screenSize.desktopSize}) {
        padding-left: ${defaultPadding.desktopPadding};
        padding-right: ${defaultPadding.desktopPadding};
    }
`;

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SplashSection = styled.div`
    margin-top: 14rem;
`;

export { HeaderStyle, HeaderTop, SplashSection };