import React from 'react';
import {
    Link
  } from "react-router-dom";
  
import * as Routes from '../../../routes';

const FooterNavigation = () => {
    return (
        <ul>
            <li>
                <Link to={Routes.LANDING}>Order food</Link>
            </li>
            <li>
                <Link to={Routes.LANDING}>About Us</Link>
            </li>
            <li>
                <Link to={Routes.LANDING}>Contact us</Link>
            </li>
            <li>
                <Link to={Routes.LANDING}>Login</Link>
            </li>
      </ul>
    )
}

export default FooterNavigation;
