import React from 'react'
import { ChangeStatusStyle, HeaderStatus, StatusStyling } from './style'

const Status = () => {
  return (
    <StatusStyling>
      <div>
        <HeaderStatus>current status</HeaderStatus>
        ordered
      </div>
      <div>
        <HeaderStatus>change status</HeaderStatus>
        <ChangeStatusStyle>
            <select name="orderstatus" id="orderstatus" onChange={(e) => console.log(e.target.value)}>
              <option value="ordered">ordered</option>
              <option value="pending">pending</option>
            </select>
            <button>Apply</button>
        </ChangeStatusStyle>
      </div>
    </StatusStyling>
  )
}

export default Status
