import React from 'react';
import DesktopNavigation from '../../desktopNavigation/DesktopNavigation';
import MobileNavigation from '../../mobileNavigation/MobileNavigation';
import { HeaderStyle, HeaderTop, SplashSection} from './style';
import CompanyLogo from '../../../assets/img/logo/logo.svg';
import { Link } from 'react-router-dom';
import * as Routes from '../../../routes';
import { useLocation } from 'react-router';
import SplashPage from '../../../pages/splash/SplashPage';

const Header = () => {
  const currentPage = useLocation().pathname;

  const imageStyling = {
    width: '13rem',
    maxwidth: '1rem',
    padding: '1rem 1rem 1rem 0',
  }

  return (
    <HeaderStyle currentPage={currentPage}>
      <HeaderTop>
        <Link to={Routes.LANDING}>
          <img src={CompanyLogo} alt="Company logo" style={imageStyling} />
        </Link>
        <MobileNavigation />
        <DesktopNavigation />
      </HeaderTop>
      
      {currentPage === '/' &&
      <SplashSection>
        <SplashPage/>
      </SplashSection>
      }

    </HeaderStyle>
  )
}

export default Header
