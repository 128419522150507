import React from 'react'
import { LabelInputStyling } from './style'

const LabelInput = (props) => {
  return (
    <LabelInputStyling>
      <input type={props.type} id={props.for} name={props.name} value={props.value} />
      <label htmlFor={props.for}>{props.value}</label>
    </LabelInputStyling>
  )
}

export default LabelInput
