import React from 'react';
import FooterNavigation from './FooterNavigation.jsx';
import FooterLegal from './FooterLegal.jsx';
import NewsLetter from './NewsLetter.jsx';
import Follow from './Follow.jsx';
import { StyledFooter, FooterFlex } from './style/index.js';
import CompanyLogo from '../../../assets/img/logo/footerLogo.svg';
import { Link } from 'react-router-dom';
import * as Routes from '../../../routes';
import { Wrapper } from '../../../layouts/style/index.js';

const Footer = () => {
  const logoStyling = {
    width: '13rem',
    maxwidth: '1rem',
    marginBottom: '1rem'
  }

  return (
    <StyledFooter>
      <Wrapper>
        <FooterFlex>
          <div>
            <Link to={Routes.LANDING}>
              <img src={CompanyLogo} alt='Company Logo' style={logoStyling}/>
            </Link>
            <FooterNavigation/>
            <Follow/>
          </div>

          <div>
            <FooterLegal/>
            <NewsLetter/>
          </div>
        </FooterFlex>
        <span>&copy; 2021 Mealsaver.com made by District9.</span>
      </Wrapper>
    </StyledFooter>
  )
}

export default Footer
