import React from 'react'
import MainNavigation from '../layout/mainNavigation/MainNavigation'
import { DesktopNavigationStyle } from './style'

const DesktopNavigation = () => {
  return (
    <DesktopNavigationStyle>
      <MainNavigation />
    </DesktopNavigationStyle>
  )
}

export default DesktopNavigation