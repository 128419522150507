import { color, borderRadius } from "../../../../style/constants";
import styled from "styled-components";

const PrimaryButton = styled.button`
    background-color: ${color.mainBlue};
    color: ${color.mainWhite};
    font-weight: bold;
    border-radius: ${borderRadius.smallRadius};
    border: none;
    padding: 1rem;
    width: 100%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    cursor: pointer;
`;

export { PrimaryButton };