import React, { useEffect, useState } from 'react'
import { InputWrap, PlaceholderWrap, FiltersStyle } from './style'

const Filters = (e) => {



  const [isOpen, setIsOpen] = useState(true)

  const [rangeValue, setRangeValue] = useState(15)
  const [radioValue, setRadioValue] = useState(0)
  const [ratingValue, setRatingValue] = useState(1)

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 1000) { setIsOpen(true) }
    })

  }, [])

  return (
    <FiltersStyle>
      <span onClick={
        () => {
          if (isOpen === false) {
            setIsOpen(true)
          } else {
            setIsOpen(false)
          }
        }
      }></span>
      {
        // filters open and close
        isOpen === true ? <>
          <div style={{ borderBottom: '1px solid grey', paddingBottom: '1rem', marginBottom: '1rem' }}>
            <h3>Radius</h3>
            <input type="range" min="0" max="25" value={rangeValue} onChange={(e) => { setRangeValue(e.target.value) }} />
            <p>{rangeValue} km</p>
          </div>
          <div style={{borderBottom: '1px solid grey', paddingBottom: '1rem', marginBottom: '1rem'}}>
            <h3>Max price</h3>
            <InputWrap onChange={(e) => { setRadioValue(e.target.value) }}>
              <PlaceholderWrap>
                <input type="radio" id="all" name="price" value="100" />
                <label htmlFor="all">all</label>
              </PlaceholderWrap>
              <PlaceholderWrap>
                <input type="radio" id="15" name="price" value="15" />
                <label htmlFor="15">€ 15</label>
              </PlaceholderWrap>
              <PlaceholderWrap>
                <input type="radio" id="50" name="price" value="50" />
                <label htmlFor="50">€ 50</label>
              </PlaceholderWrap>
            </InputWrap>
          </div>
          <div>
            <h3>Ratings</h3>
            <InputWrap onChange={(e) => { setRatingValue(e.target.value) }}>
              <PlaceholderWrap>
                <input type="radio" id="1" name="rating" value="1" />
                <label htmlFor="1">€ 1</label>
              </PlaceholderWrap>
              <PlaceholderWrap>
                <input type="radio" id="2" name="rating" value="2" />
                <label htmlFor="2">€ 2</label>
              </PlaceholderWrap>
              <PlaceholderWrap>
                <input type="radio" id="3" name="rating" value="3" />
                <label htmlFor="3">€ 3</label>
              </PlaceholderWrap>
              <PlaceholderWrap>
                <input type="radio" id="4" name="rating" value="4" />
                <label htmlFor="4">€ 4</label>
              </PlaceholderWrap>
              <PlaceholderWrap>
                <input type="radio" id="5" name="rating" value="5" />
                <label htmlFor="5">€ 5</label>
              </PlaceholderWrap>
            </InputWrap>
          </div>
        </>
          : ''
      }
    </FiltersStyle>
  )
}

export default Filters
