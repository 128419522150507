import React from 'react'
import { useField, ErrorMessage } from 'formik'
import { StyledLabel, StyledInput } from './index';


const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={field.name}>{label}</StyledLabel>
      <StyledInput {...field} {...props} />
      {/* <ErrorMessageStyle><ErrorMessage name={field.name} /></ErrorMessageStyle> */}
    </>
  )
}

export default TextField
