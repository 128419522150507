import React from 'react'
import { OrderContent, OrderStyling, TotalPrice } from './style'

const Order = (props) => {
  return (
    <OrderStyling>
      <h3>Order #1</h3>
      <OrderContent>
        <div className="meals">
          <div className="mealsTitleWrap">
            <p>{props.type}</p>
            <p>Qty</p>
            <p>Price</p>
          </div>
          <div className="mealsOrder">
            {/* props hier */}
            <p>small meal</p>
            <p>2</p>
            <p>€ 10</p>
          </div>
          <div className="mealsOrder">
            {/* props hier */}
            <p>small meal</p>
            <p>2</p>
            <p>€ 10</p>
          </div>
        </div>
      </OrderContent>
      <TotalPrice>
        <p>Total</p>
        <p>€ 60</p>
      </TotalPrice>
    </OrderStyling>
  )
}

export default Order
