import { screenSize } from "../../../style/constants";
import styled from "styled-components";

const ProfileNavigationStyling = styled.ul`
  padding: 1rem 0;

  display: flex;
  justify-content: space-between;

  li {
     a{
       font-size: 1rem;
     }
  }

  @media (min-width: ${screenSize.desktopSize}) {
    height: 8rem;
    width: 15rem;

    flex-direction: column;
    
  }
`;

export { ProfileNavigationStyling };