import styled from "styled-components";
import { borderRadius, color } from "../../../style/constants";

const FormStyle = styled.form`
  margin: 2rem 0;
  width: 100%;
  max-width: 900px;
  margin: 5rem auto;

  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  color: ${color.secondaryBlue};
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const StyledInput = styled.input`
  padding: 0.7rem;
  border: none;
  margin-bottom: 1rem;
  border-radius: ${borderRadius.smallRadius};
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const StyledTextfield = styled.textarea`
  padding: 0.7rem;
  border: none;
  margin-bottom: 1rem;
  border-radius: ${borderRadius.smallRadius};
  resize: none;
  height: 10rem;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const ErrorMessageStyle = styled.small`
  color: red;
  font-size: 0.9rem;
  margin-bottom: 1rem;
`

export { FormStyle, StyledLabel, StyledInput, StyledTextfield, ErrorMessageStyle };