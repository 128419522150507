import React from 'react';
import { Link } from 'react-router-dom';
import Facebook  from '../../../assets/img/social/facebook.svg';
import Instagram  from '../../../assets/img/social/instagram.svg';
import Twitter from '../../../assets/img/social/twitter.svg';
import Pintrest from '../../../assets/img/social/pintrest.svg';
import { SocialUl } from './style';

const Follow = () => {
    return (
        <div>
            <h3>Follow us</h3>
            <SocialUl>
                <li>
                    <Link to=''>
                        <img src={Facebook} alt="Facebook icon"/>
                    </Link>
                </li>
                <li>
                    <Link to=''>
                        <img src={Instagram} alt="Instagram icon"/>
                    </Link>
                </li>
                <li>
                    <Link to=''>
                        <img src={Twitter} alt="Facebook icon"/>
                    </Link>
                </li>
                <li>
                    <Link to=''>
                        <img src={Pintrest} alt="Facebook icon"/>
                    </Link>
                </li>
            </SocialUl>
        </div>
    )
}

export default Follow;
