import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { BaseLayout } from '../../layouts'
import mapImage from '../../assets/img/map_placeholder.png'
import { ProductDetailStyle, ProductDetailInfo, ProductDetailImageContainer, ProductDetailInfoContainer, OrderCardContainer } from './style/index';
import OrderCard from '../../components/orderCard/OrderCard';
import { MealsContext } from '../restaurantAvailable/RestaurantAvailableContext';
import { OrdersContext } from './OrderDetailContext';
import { GET_RESTAURANTS_AND_ORDERS } from '../../graphql/restaurants'
import { GET_PRODUCTS_FROM_SPECIFIC_RESTAURANT } from '../../graphql/restaurants'
import { useQuery } from '@apollo/client';


const ProductDetail = ({ match }) => {
  // const { loading, error, data } = useQuery(GET_PRODUCTS_FROM_SPECIFIC_RESTAURANT);

  const { loading, error, data } = useQuery(GET_RESTAURANTS_AND_ORDERS);

  if (!loading) {
    data.getRestaurants.map(d => {
      if (d.id == match.params.id) {
        const res = d.products.map(product => {
          return product
        })
        console.log(res)
      }
    })
  }

  const [meals, setMeals] = useContext(MealsContext);
  const [orders, setorders] = useContext(OrdersContext);

  if (loading) { return (<h2>LOADING......</h2>) }
  if (error) { return 'Something went wrong...' }


  return (
    <BaseLayout>
      {
        (() => {
          if (!loading) {
            let filteredRestaurant = data.getRestaurants.filter(restaurant => restaurant.id == match.params.id);
            return (
              <ProductDetailStyle>
                <ProductDetailInfo>
                  <ProductDetailInfoContainer>
                    <h2>{filteredRestaurant[0].name}</h2>
                    <p>{filteredRestaurant[0].description}</p>
                    <Link to="">Visit our website here</Link>
                  </ProductDetailInfoContainer>
                  <ProductDetailImageContainer>
                    <img src={filteredRestaurant[0].image} alt="map" />
                  </ProductDetailImageContainer>
                </ProductDetailInfo>

                <OrderCardContainer>
                  <h2>Food</h2>
                  {
                    filteredRestaurant[0].products.map(product => {
                      if (product.product.type === "meal") {
                        return (
                          <OrderCard mealName={product.product.name} portions={product.amount} price={product.price} />
                        )
                      }
                    })
                  }
                  <h2>Drinks</h2>
                  {
                    filteredRestaurant[0].products.map(product => {
                      if (product.product.type === "drink") {
                        return (
                          <OrderCard mealName={product.product.name} portions={product.amount} price={product.price} />
                        )
                      }
                    })
                  }
                </OrderCardContainer>
              </ProductDetailStyle>
            )
          }
        })()
      }
    </BaseLayout>
  )
}

export default ProductDetail
