import React from 'react'
import { BaseLayout } from '../../layouts'
import * as Yup from 'yup';
import { Formik } from 'formik';
import { StyledForm } from '../profileEdit/style';
import { FormFlex } from '../checkout/style';
import CheckoutForm from '../checkout/CheckoutForm';
import { Button, SelectStylingDiv } from './style';
import LabelInput from './LabelInput';


const Signup = () => {
  const validate = Yup.object({
    street: Yup.string().required('This field is required'),
    number: Yup.string().required('This field is required'),
    city: Yup.string().required('This field is required'),
    zip: Yup.string().required('This field is required'),
    companyName: Yup.string().required('This field is required'),
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    email: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().required('This field is required'),
  })
  return (
    <BaseLayout>
      <h1>Sign up</h1>
      <Formik
        initialValues={{
          street: '',
          number: '',
          city: '',
          zip: '',
          companyName: '',
          notes: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: ''
        }}
        validationSchema={validate}
      >
        {
          formik => (
            <StyledForm>
              <div>
                <CheckoutForm type="email" name="email" label="Email" />
                <CheckoutForm type="password" name="password" label="password" />
                <CheckoutForm type="password" name="confirmPassword" label="confirm password" />

                <FormFlex>
                  <CheckoutForm type="text" name="street" label="Street" />
                  <CheckoutForm type="text" name="number" label="Nr" />
                </FormFlex>

                <FormFlex>
                  <CheckoutForm type="text" name="city" label="City" />
                  <CheckoutForm type="text" name="zip" label="Zip" />
                </FormFlex>

                <CheckoutForm type="tel" name="phone" label="phone" />

                <SelectStylingDiv>
                  <p>I'm a...</p>
                  <div className="wrapped">
                    <LabelInput type="radio" name="mealsaver" value="mealsaver" for="mealsaver" />
                    <LabelInput type="radio" name="mealsaver" value="mealeater" for="mealeater" />
                  </div>
                </SelectStylingDiv>
              </div>
              <Button type="submit">Register</Button>
            </StyledForm>
          )
        }
      </Formik>
    </BaseLayout>
  )
}

export default Signup
