import React from 'react'
import { BaseLayout } from '../../layouts'
import AboutImage from '../../assets/img/about/slogan.svg'
import Clients from './Clients'
import { AboutFlex, AboutStyling, StyledLink, TestemonialStyling } from './style'

const AboutUs = () => {
    return (
        <BaseLayout>
            <AboutStyling>
                <AboutFlex>
                    <div>
                        <h2>Save food and the environment</h2>
                        <p>MealSaver is a website that gives restaurants the possibility to sell good leftover foods at discounted prices. MealSaver also allows people to look for delicious meals at a lower price. Our goal is to prevent food waste and offer customers the possibility to choose a variety of different dishes available in their neighbourhood.</p>
                        <StyledLink href="contact-page">Contact us</StyledLink>
                    </div>
                    <div>
                        <img src={AboutImage} alt='about us'/>
                    </div>
                </AboutFlex>
                <Clients/>
                <TestemonialStyling>
                    <h2>Testimonials</h2>
                    <div>
                        <span>David M. from Toronto</span>
                        <p>MealSaver has some amazing deals! I was surprised by the number of restaurants that are offering food at half of the usual price. I highly recommend it! </p>
                    </div>
                    <div>
                        <span>Jane P. from Toronto</span>
                        <p>I have a large family and going to the restaurant can sometimes be very expensive! With MealSaver, we had the opportunity to try different restaurants in our neighbourhood.</p>
                    </div>
                </TestemonialStyling>
            </AboutStyling>
        </BaseLayout>
    )
}

export default AboutUs
