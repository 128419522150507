import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as Routes from './routes';
import { HomePage } from './pages';
import ContactPage from './pages/contact-page/ContactPage';
import AboutUs from './pages/about-us/AboutUs';
import SearchPage from './pages/searchpage/SearchPage';
import RestaurantAvailable from './pages/restaurantAvailable/RestaurantAvailable';
import RestaurantOrders from './pages/restaurantOrders/RestaurantOrders';
import AccountOverview from './pages/accountOverview/AccountOverview';
import OrderPlaced from './pages/order-placed/OrderPlaced';
import ProductDetail from './pages/productDetail/ProductDetail';
import Checkout from './pages/checkout/Checkout';
import AccountOrders from './pages/accountOrders/AccountOrders';
import ProfileEdit from './pages/profileEdit/ProfileEdit';
import Signup from './pages/signup/Signup';
import Login from './pages/login/Login';
import { MealsProvider } from './pages/restaurantAvailable/RestaurantAvailableContext';
import Faq from './pages/faq/Faq';
import { OrdersProvider } from './pages/productDetail/OrderDetailContext';
import ShoppingCart from './pages/shoppingCart/ShoppingCart';

function App() {
  return (
    <MealsProvider>
      <OrdersProvider>
        <div className="App">
          <Router basename={'/'}>
            <Switch>
              <Route exact path={Routes.LANDING} component={HomePage} />
              <Route exact path={Routes.SIGNUP} component={Signup} />
              <Route exact path={Routes.LOGIN} component={Login} />
              <Route exact path={Routes.CONTACT} component={ContactPage} />
              <Route exact path={Routes.ABOUT} component={AboutUs} />
              <Route exact path={Routes.SEARCH} component={SearchPage} />
              <Route exact path={Routes.RESTAURANTAVAILABLE} component={RestaurantAvailable} />
              <Route exact path={Routes.RESTAURANTORDERS} component={RestaurantOrders} />
              <Route exact path={Routes.PRODUCTDETAIL} component={ProductDetail} />
              <Route exact path={Routes.ACCOUNTOVERVIEW} component={AccountOverview} />
              <Route exact path={Routes.ACCOUNTORDERS} component={AccountOrders} />
              <Route exact path={Routes.ORDERPLACED} component={OrderPlaced} />
              <Route exact path={Routes.SHOPPINGCART} component={ShoppingCart} />
              <Route exact path={Routes.CHECKOUT} component={Checkout} />
              <Route exact path={Routes.EDITPROFILE} component={ProfileEdit} />
              <Route exact path={Routes.FAQ} component={Faq} />
            </Switch>
          </Router>
        </div>
      </OrdersProvider>
    </MealsProvider>
  );
}

export default App;
