import React from 'react'
import { Highlight, H2, StepsDiv, IconTextWrap, SmallImg, Paragraph} from './style';

import CutleryIcon from '../../assets/img/icons/cutlery-icon.svg';
import LocationPinIcon from '../../assets/img/icons/location-pin-icon.svg';
import PaymentIcon from '../../assets/img/icons/payment-icon.svg';
import MealIcon from '../../assets/img/icons/meal-icon.svg';
import WalkingIcon from '../../assets/img/icons/walking-icon.svg';
import RestaurantIcon from '../../assets/img/icons/restaurant-icon.svg';
import ArrowIcon from '../../assets/img/icons/arrow-icon.svg';
<p></p>
const Steps = () => {
  return (
    <section>
      <H2>Get your meal in <Highlight>6</Highlight> easy steps.</H2>
      <Paragraph>With MealSaver, it is easy to find discounted meals in your area.</Paragraph>
      <StepsDiv>
        <IconTextWrap>
          <img src={LocationPinIcon} alt="icon" />
          <p>Pick</p>
        </IconTextWrap>
        <SmallImg>
          <img src={ArrowIcon} alt="icon" />
        </SmallImg>
        <IconTextWrap>
          <img src={RestaurantIcon} alt="icon" />
          <p>Choose</p>
        </IconTextWrap>
        <SmallImg>
          <img src={ArrowIcon} alt="icon" />
        </SmallImg>
        <IconTextWrap>
          <img src={MealIcon} alt="icon" />
          <p>Order</p>
        </IconTextWrap>
        <IconTextWrap>
          <img src={PaymentIcon} alt="icon" />
          <p>Pay</p>
        </IconTextWrap>
        <SmallImg>
          <img src={ArrowIcon} alt="icon" />
        </SmallImg>
        <IconTextWrap>
          <img src={WalkingIcon} alt="icon" />
          <p>Aquire</p>
        </IconTextWrap>
        <SmallImg>
          <img src={ArrowIcon} alt="icon" />
        </SmallImg>
        <IconTextWrap>
          <img src={CutleryIcon} alt="icon" />
          <p>Enjoy</p>
        </IconTextWrap>
      </StepsDiv>

    </section>
  )
}

export default Steps
