import React from 'react'
import { BaseLayout } from '../../layouts'
import { Formik } from 'formik'
import SubmitButton from '../../components/buttons/SubmitButton/SubmitButton';
import TextField from './style/TextField';
import { FormStyle, ContactPageLayout, ContactStyle, ContactText } from './style';
import * as Yup from 'yup';
import contactIllustrationImage from '../../assets/img/illustration-contact-page.svg';

const ContactPage = () => {
  const validate = Yup.object({
    fullName: Yup.string().required('This field is required'),
    email: Yup.string().email('Email is invalid').required('This field is required'),
    message: Yup.string().required('This field is required')
  })
  return (
    <BaseLayout>
      <ContactStyle>
        <h2>Get in touch</h2>
        <ContactPageLayout>
          <Formik
            initialValues={{
              fullName: '',
              email: '',
              password: ''
            }}
            validationSchema={validate}
          >
            {
              formik => (
                <FormStyle>
                  <TextField label="Full name" name="fullName" type="text" />
                  <TextField label="email" name="email" type="email" />
                  <TextField label="message" name="message" type="text" />
                  <SubmitButton>Send</SubmitButton>

                  <ContactText>
                    At MealSaver, we want to allow everyone to have access to affordable meals. We also care about sustainability and food waste. If you have any concerns, please reach out to us, and one of our MealSaver Customer Care specialists will get back to you.
                  </ContactText>
                </FormStyle>
              )}
          </Formik>
          <img style={{width: '100%'}} src={contactIllustrationImage} alt="contact page illustration" />
        </ContactPageLayout>
      </ContactStyle>
    </BaseLayout>
  )
}

export default ContactPage
