import React from 'react';
import { PrimaryButton } from './style';

const SubmitButton = ({children}) => {
    return (
        <PrimaryButton>
            {children}
        </PrimaryButton>
    )
}

export default SubmitButton
