import React from 'react'
import { BaseLayout } from '../../layouts'
import Orders from './Orders'
import { H2 } from './style'
// import 2x gql imports

const RestaurantOrders = () => {

  // usequery
  // data.getOrders.map


  return (
    <BaseLayout>
      <H2>orders (3)</H2>
      <Orders />
    </BaseLayout>
  )
}

export default RestaurantOrders
