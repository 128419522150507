import { color, borderRadius, screenSize } from "../../../style/constants";
import styled from "styled-components";


const OrderPageStyle = styled.div`
    margin-bottom: 4rem;
    margin-top: 3rem;

    h2 {
        text-align: center;
        margin-bottom: 1rem;
    }
`

const OrderPageImageContainer = styled.div`
    width: 100%;
    height: 20rem;
    margin-bottom: 2rem;

    @media (min-width: ${screenSize.tabletSize}) {
        height: 25rem;
    }

    @media (min-width: ${screenSize.desktopSize}) {
        height: 35rem;
    }

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        border-radius: ${borderRadius.mediumRadius};
    }
`

const OrderPageInfo = styled.div`
    h3 {
        color: ${color.mainOrange};
        margin-bottom: 1rem;
    }

    p {
        color: ${color.mainWhite};
    }
`

const BackgroundColor = styled.div`
    background-color: ${color.mainBlue};
    position: absolute;
    height: 20rem;
    width: 100%;
    z-index: -1;
    top: -10rem;
`

const BackgroundWrapper = styled.div`
    position: relative;
`

export {
    OrderPageStyle,
    OrderPageImageContainer,
    OrderPageInfo,
    BackgroundColor,
    BackgroundWrapper
}