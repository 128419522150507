import { Link } from "react-router-dom";
import styled from "styled-components";
import { borderRadius, defaultMargin } from "../../../style/constants";
import { screenSize, color } from "../../../style/constants";

const SplashPageStyle = styled.div`
`;

const H1 = styled.h1`
  text-align: center;
  margin: ${defaultMargin.mediumMargin} 0;
`

const H2 = styled.h2`
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
  max-width: 100%;
`

const Highlight = styled.span`
  color: ${color.mainOrange};
`;


const InputStyle = styled.input`
  color: ${color.mainBlack};
  border-radius: 50px;
  border: 1px solid grey;
  padding: 0.7rem 1.2rem;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  width: 40rem;
  max-width: 100%;
  height: 3rem;
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  max-width: 100%;
`

const SubmitButton = styled(Link)`
background: ${color.mainOrange};
  position : absolute;
  right: 0.2rem;
  top: 0.2rem;
  border: none;
  border-radius: 20px;
  padding: 0.6rem 1.9rem;
`;

/**
 * STEPS
 */

const StepsDiv = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: repeat(2, 10rem);
`;

const IconTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1rem;
  }

  img {
    width: 2rem;
  }
`;

const SmallImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
    img {
      width: 1rem;
    }
`;

/**
 * SUGGESTIONS
 */

const SuggestionsStyle = styled.div`
  overflow: auto;
  white-space: nowrap;
  margin-bottom: 2rem;

  @media (min-width: ${screenSize.tabletSize}) {
    white-space: normal; 
    display: flex;
  }
`;

const RestaurantCardStyle = styled.div`
  margin-right: 1rem;
  width: 100%;
  margin-bottom: 2rem;
  transition: 0.2s ease;
  display: inline-flex;
  flex-direction: column;

  &:hover {
    transform: scale(1.03);
  }

  img {
    width: 100%;
    border-radius: ${borderRadius.smallRadius};
  }
`;

const RestaurantCardWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TagsWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const TagStyling = styled.span`
  background: ${color.secondaryBlue};
  color: ${color.mainWhite};
  font-size: 1.05rem;
  padding: 0.5rem 1.5rem;
  margin-top: 1rem;
  margin-right: 1rem;
  border-radius: ${borderRadius.smallRadius};
  text-align: center;
`;

/**
 * PHONE AD
 */

const PhoneAdStyle = styled.div`
  margin: 5rem 0;

  display: flex;
  flex-direction: column;

  @media (min-width: ${screenSize.tabletSize}) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const LinkSpan = styled.a`
  color: ${color.mainOrange};
  font-size: 2rem;
`;

const SuggestionH2 = styled.h2`
  margin-bottom: 2rem;
`;

export { SplashPageStyle, H1, H2, InputStyle, SubmitButton, FormWrapper, Highlight, StepsDiv, IconTextWrap, SmallImg, SuggestionsStyle, RestaurantCardStyle, RestaurantCardWrap, TagsWrap, TagStyling, LinkSpan, PhoneAdStyle, InputWrapper, SuggestionH2, Paragraph };