export const color = {
    mainBlue: '#48577A',
    secondaryBlue: '#2f4858',
    tertiaryBlue: '#5C798B',
    mainOrange: '#fd8767',
    mainWhite: '#f9f9f9',
    secondaryWhite: '#EFECF0',
    mainBlack: '#1a1a1a'
}

export const borderRadius = {
    smallRadius: '5px',
    mediumRadius: '10px',
    largeRadius: '50px',
}

export const defaultPadding = {
    mobilePadding: '1rem',
    tabletPadding: '2rem',
    desktopPadding: '4rem'
}

export const defaultMargin = {
    smallMargin: '1rem',
    mediumMargin: '2rem',
    largeMargin: '4rem'
}

export const screenSize = {
    tabletSize: '750px',
    desktopSize: '1120px'
}