import React from 'react'
import { SecondaryLayout } from '../../layouts'
import { InnerWrapper } from '../../layouts/style';
import mapPlaceholder from '../../assets/img/order-placed/map.png'
import { OrderPageStyle, OrderPageImageContainer, OrderPageInfo, BackgroundColor, BackgroundWrapper } from './style';

const orderPlaced = () => {
    return (
        <SecondaryLayout>
            <OrderPageStyle>
                <InnerWrapper>
                    <h2>Your order has been placed!</h2>
                </InnerWrapper>

                <InnerWrapper>
                    <OrderPageImageContainer>
                        <img src={mapPlaceholder} alt="map"/>
                    </OrderPageImageContainer>
                </InnerWrapper>
                
                <BackgroundWrapper>
                    <BackgroundColor></BackgroundColor>

                    <InnerWrapper>
                        <OrderPageInfo>
                            <h3>Pick up your meal at:</h3>
                            <p>Great restaurant</p>
                            <p>Street 101</p>
                            <p>9000 Ghent</p>
                        </OrderPageInfo>
                    </InnerWrapper>
                </BackgroundWrapper>
            </OrderPageStyle>
        </SecondaryLayout>
    )
}

export default orderPlaced
