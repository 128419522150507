import React from 'react'
import { BaseLayout } from '../../layouts'
import { FaqStyled } from './style'

const Faq = () => {
    return (
        <BaseLayout>
            <FaqStyled>
                <h2>FAQ</h2>

                <div>
                    <h3>Who uses MealSaver?</h3>
                    <p>MealSaver is used by customers who look for a cheaper alternative to regular food delivery apps and restaurant owners who want to prevent food waste. </p>
                </div>

                <div>
                    <h3>Where does MealSaver operate?</h3>
                    <p>MealSaver is currently available in Belgium and Canada. </p>
                </div>

                <div>
                    <h3>I am a customer. How does MealSaver work?</h3>
                    <p>Go to the MealSaver Home page and register for an account. </p>
                    <p>Once the account has been created, use the following steps:</p>
                    <ol>
                        <li>Type your address in the search bar</li>
                        <li>Choose a restaurant</li>
                        <li>Place and order</li>
                        <li>Pay using a credit card or Paypal</li>
                        <li>Pick up your meal at the restaurant</li>
                        <li>Enjoy your dish!</li>
                    </ol>
                </div>
                
                <div>
                    <h3>I am a restaurant owner. How does MealSaver work?</h3>
                    <p>Go to the MealSaver Home page and register for an account.</p>
                    <p>Once the account has been created, use the following steps:</p>
                    <ul>
                        <li>Select the available meals for pickup</li>
                        <li>Edit the prices and quantities</li>
                        <li>Accept incoming orders</li>
                        <li>Provide customers with meals!</li>
                    </ul>
                </div>

                <div>
                    <h3>I placed an order at a restaurant. How do I know the restaurant has confirmed it?</h3>
                    <p>When you pay for your order, the website will show the map and the restaurant's location. You will receive a confirmation e-mail that the order has been placed with your order details.</p>
                </div>

                <div>
                    <h3>Is it safe to pay on MealSaver with my credit card?</h3>
                    <p>MealSaver uses SSL technology that encrypts your connection to our website. It ensures that any information that you provide will automatically be safeguarded.</p>
                </div>

                <div>
                    <h3>I am a customer and I forgot my username and password. What should I do?</h3>
                    <p>If you are having difficulties remembering your username and password, you can reach out to our Customer support center.</p>
                    <p>Go to the MealSaver home page</p>
                    <ul>
                        <li>Click on Contact in the navigation bar</li>
                        <li>Input your full name, e-mail and message</li>
                        <li>Click on Send.</li>
                    </ul>
                    <p>Our customer support agents will be in touch with you in less than 24 hours.</p>
                </div>

                <div>
                    <h3>I am a restaurant owner and I have questions about my account. How can I reach out to MealSaver?</h3>
                    <p>You can reach out to our Customer support center.</p>
                    <p>Go to the MealSaver home page</p>
                    <ul>
                        <li>Click on Contact in the navigation bar</li>
                        <li>Input your full name, e-mail and message</li>
                        <li>Click on Send</li>
                    </ul>
                    <p>Our customer support agents will be in touch with you in less than 24 hours.</p>
                </div>
            </FaqStyled>
        </BaseLayout>
    )
}

export default Faq
