import React from 'react';
import SubscribeButton from '../../buttons/SubscribeButton/SubscribeButton';
import { NewsDiv, NewsForm } from './style';

const NewsLetter = () => {
    return (
        <NewsForm>
            <h3>Subscribe to our newsletter</h3>
            <NewsDiv>
                <input htmlFor='mail'></input>
                <SubscribeButton>Subscribe</SubscribeButton>
            </NewsDiv>
        </NewsForm>
    )
}

export default NewsLetter;
