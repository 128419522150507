import React from 'react'
import { SuggestionsStyle, SuggestionH2 } from './style'
import RestaurantCard from '../../components/restaurantCard/RestaurantCard'
import { GET_RESTAURANTS } from '../../graphql/restaurants'
import { useQuery } from '@apollo/client';

// deze image is placeholder en mag verwijderd worden eens het restaurant zelfe een img kan uploaden
import SplashBg from '../../assets/img/splash-background.png';

const Suggestions = () => {
  const { loading, error, data } = useQuery(GET_RESTAURANTS);

  if (loading) { return (<h2>LOADING......</h2>) }
  if (error) { return 'Something went wrong...' }

  let filteredData = data.getRestaurants.slice(0, 3);

  return (
    <>
      <SuggestionH2>Suggestions</SuggestionH2>
      <SuggestionsStyle>
        {
          filteredData.map((e) => {
            return (
              <RestaurantCard
                bgimage={`https://picsum.photos/1920/1080`}
                restaurantname={e.name}
                id={e.id}
                score={7.5}
                mealcount={5}
              />
            )
          })
        }
      </SuggestionsStyle>
    </>
  )
}

export default Suggestions
