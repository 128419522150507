import styled from "styled-components";
import { screenSize, borderRadius, color } from "../../../style/constants";

const AboutStyling = styled.div`
    padding-top: 3rem;
    
    h2 {
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 2rem;
    }

    button {
        width: 100%;

        @media (min-width: ${screenSize.tabletSize}) {
            width: inherit;
        }
    }

    img {
        width: 100%;
        margin-top: 2rem;
    }
`

const AboutFlex = styled.div`
    margin-bottom: 4rem;

    @media (min-width: ${screenSize.tabletSize}) {
        display: flex;
        justify-content: space-between;
    }

    div {
        @media (min-width: ${screenSize.tabletSize}) {
            width: 48%;
        }
    }
`

const ClientStyling = styled.div`
    margin-bottom: 3rem;

    h3 {
        margin-bottom: 2rem;
    }
`

const ClientUl = styled.ul`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
        width: 30%;

        @media (min-width: ${screenSize.tabletSize}) {
            width: 15%;
        }

        img {
            width: 100%;
            border-radius: ${borderRadius.largeRadius};
            filter: brightness(60%);
            transition: 0.2s ease;

            &:hover {
                filter: brightness(100%);
            }
        }
    }
`

const StyledLink = styled.a`
  background: ${color.mainBlue};
  color: ${color.mainWhite};
  font-size: 1.25rem;
  text-align: center;
  display: block;
  border-radius: 50px;
  padding: 0.7rem 1rem;

  @media (min-width: ${screenSize.desktopSize}) {
    max-width: 20rem;
  }
`;

const TestemonialStyling = styled.div`
    margin-bottom: 3rem;
    
    span {
        color: ${color.mainOrange};
    }

    p {
        max-width: 40rem;
        font-style: italic;
    }
`

export {
    AboutStyling,
    AboutFlex,
    ClientStyling,
    ClientUl,
    TestemonialStyling,
    StyledLink
}