import React, { useContext, useState } from 'react'
import { BaseLayout } from '../../layouts'
import OrderCard from '../../components/orderCard/OrderCard';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CheckoutForm from './CheckoutForm';
import { CheckoutStyled, StyledTitle, StyledBasket, StyledPrice, FormFlex, StyledForm, CheckoutFlex, SelectForm } from './style';
import SubmitButton from '../../components/buttons/SubmitButton/SubmitButton';
import { MealsContext } from '../restaurantAvailable/RestaurantAvailableContext';
import { OrdersContext } from '../productDetail/OrderDetailContext';

const Checkout = () => {

  const [orders, setorders] = useContext(OrdersContext);


  const validate = Yup.object({
    street: Yup.string().required('This field is required'),
    number: Yup.string().required('This field is required'),
    city: Yup.string().required('This field is required'),
    zip: Yup.string().required('This field is required'),
    companyName: Yup.string().required('This field is required'),
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    email: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().required('This field is required'),
  })

  function amount(item) {
    return (item.price * item.amount)
  }

  function sum(prev, next) {
    return prev + next;
  }

  const basketPrice = orders.map(amount).reduce(sum);

  return (
    <BaseLayout>
      <CheckoutStyled>
        <div>
          <h2>Checkout</h2>
        </div>

        <CheckoutFlex>
          <StyledBasket>
            <StyledTitle>Basket</StyledTitle>
           {
              orders.map(order => {
                return <div className="basketOrder">
                  <span className="basketOrder__mealname">{order.meal} meal</span>
                  <span className="basketOrder__mealprice">€{order.price}</span>
                  <span>{order.amount}</span>
                </div>;
              })
           }

            <StyledPrice>
              <p>Total</p>
              <p>€ {basketPrice}</p>
            </StyledPrice>
          </StyledBasket>

          <Formik
            initialValues={{
              street: '',
              number: '',
              city: '',
              zip: '',
              companyName: '',
              notes: '',
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: ''
            }}
            validationSchema={validate}
          >
            {
              formik => (
                <StyledForm>
                  <div>
                    <StyledTitle>Delivery Address</StyledTitle>

                    <FormFlex>
                      <CheckoutForm type="text" name="street" label="Street" />
                      <CheckoutForm type="number" name="number" label="Nr" />
                    </FormFlex>

                    <FormFlex>
                      <CheckoutForm type="text" name="city" label="City" />
                      <CheckoutForm type="number" name="zip" label="Zip" />
                    </FormFlex>

                    <CheckoutForm type="text" name="companyName" label="Company name" />
                    <CheckoutForm type="text" name="notes" label="Notes" />

                    <StyledTitle>Personal Details</StyledTitle>

                    <CheckoutForm type="text" name="firstName" label="First name" />
                    <CheckoutForm type="text" name="lastName" label="Last name" />
                    <CheckoutForm type="email" name="email" label="Email" />
                    <CheckoutForm type="tel" name="phoneNumber" label="Phone number" />

                    <StyledTitle>Payment</StyledTitle>

                    <SelectForm name="payment">
                      <option value="creditCard">Credit Card</option>
                      <option value="masterCard">Master Card</option>
                      <option value="PayPal">PayPal</option>
                    </SelectForm>
                  </div>
                  {/* change button to link */}
                  <SubmitButton>Checkout</SubmitButton>
                </StyledForm>
              )
            }
          </Formik>
        </CheckoutFlex>
      </CheckoutStyled>
    </BaseLayout>
  )
}

export default Checkout
