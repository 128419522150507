import React, { useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai';
import MainNavigation from '../layout/mainNavigation/MainNavigation';
import { MobileNavigationStyle } from './style';


const MobileNavigation = () => {

  const [hamburgerIsOpen, setHamburgerIsOpen] = useState(false)

  return (
    <MobileNavigationStyle>
      <AiOutlineMenu size='2rem' onClick={() => setHamburgerIsOpen(!hamburgerIsOpen)} />
      {hamburgerIsOpen && <MainNavigation />}
    </MobileNavigationStyle>
  )
}

export default MobileNavigation