import React from 'react';
import { NewsButton } from './style';

const SubscribeButton = ({children}) => {
    return (
        <NewsButton type="submit">
            {children}
        </NewsButton>
    )
}

export default SubscribeButton
