import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BaseLayout } from '../../layouts'
import AvailableMeals from './AvailableMeals'
import { MealsContext } from './RestaurantAvailableContext'
import { AvailableProductStyling } from './style';
import { GET_RESTAURANTS } from '../../graphql/restaurants'
import { useQuery } from '@apollo/client';



const RestaurantAvailable = (props) => {

  const [meals, setMeals] = useContext(MealsContext);

  const { loading, error, data } = useQuery(GET_RESTAURANTS);


  return (
    <BaseLayout>
      {
        (() => {
          if (!loading) {
            let filteredRestaurant = data.getRestaurants.filter(restaurant => restaurant.id == props.match.params.id);
            return (
              <AvailableProductStyling>
                <h2>Available products from {filteredRestaurant[0].name}</h2>
                <AvailableMeals />
                <Link to={`../productDetail/${filteredRestaurant[0].id}`} style={{ background: '#2F4858', color: 'white', padding: '0.5rem 1rem', display: 'inline-block', borderRadius: '50px', marginBottom: '1rem', marginTop: '1rem' }}>Go to customer page</Link>
              </AvailableProductStyling>
            )
          }
        })()
      }
    </BaseLayout>
  )
}

export default RestaurantAvailable
