import styled from "styled-components";
import { screenSize, color } from "../../../../style/constants";

const MainNavigationStyle = styled.ul`
background: ${color.secondaryBlue};
color: ${color.mainWhite};
position: absolute;
right: 0;
top: 4rem;
width: 100%;
height: calc(100vh - 4rem);
text-align: center;
z-index: 999;
li {
  padding: 1rem 0;
  transition: 0.2s ease;

  a {
    transition: 0.2s ease;
  }
}

li:hover {
  a {
    color: ${color.mainOrange};
  }
}

a {
  display: inline-block;
  }


  @media (min-width: ${screenSize.tabletSize}) {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      padding: 1rem;
    }

    li:last-child {
      a {
        padding-right: 0;
      }
    }

    a:hover {
    color: white;
    }
  }

`;

const ShoppingAmount = styled.span`
  color: ${color.mainWhite};
`;

const ShoppingStyle = styled.li`
  a {
    position: relative;
  }

  span {
    position: absolute;
    top: 2rem;
    left: 0.75rem;
    background-color: ${color.mainOrange};
    padding: 0 0.35rem;
    border-radius: 100%;
    font-size: 0.95rem;
    font-weight: bold;
  }

  img {
    width: 2rem;
    margin-right: 0.25rem;
  }
`

export { MainNavigationStyle, ShoppingAmount, ShoppingStyle };