import { color, borderRadius } from "../../../../style/constants";
import styled from "styled-components";

const NewsButton = styled.button`
    background-color: ${color.mainBlue};
    color: ${color.mainWhite};
    border-radius: ${borderRadius.largeRadius};
    outline: none;
    border: none;
    padding: 0.5rem 1rem;
    transition: 0.2s ease;

    &:hover {
        background-color: ${color.secondaryBlue};
    }
`

export { NewsButton };