import React from 'react';
import { Footer, Header } from '../components/layout';
import { GlobalStyling } from '../style/GlobalStyle';
import { Wrapper } from './style';

const BaseLayout = ({ children }) => {
  return (
    <>
      <GlobalStyling />
      <Header />
      <Wrapper>
        {children}
      </Wrapper>
      <Footer />
    </>
  )
}

export default BaseLayout;
