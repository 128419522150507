import { createGlobalStyle } from "styled-components";
import { color, screenSize } from "./constants";

const GlobalStyling = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        list-style: none;
        box-sizing: border-box;
        text-decoration: none;
        color: inherit;
        word-break: break-word;
        font-weight: inherit;
        font-size: inherit;
    }

    body {
        font-family: 'Nunito', sans-serif;
        background-color: ${color.mainWhite};
    }

    h1 {
        font-size: 2rem;
        font-weight: bold;

        @media (min-width: ${screenSize.desktopSize}) {
            font-size: 3rem;
        }
    }

    h2 {
        font-size: 1.5rem;
        font-weight: bold;

        @media (min-width: ${screenSize.desktopSize}) {
            font-size: 2rem;
        }
    }

    h3 {
        font-size: 1.25rem;
        font-weight: bold;

        @media (min-width: ${screenSize.desktopSize}) {
            font-size: 1.5rem
        }
    }

    p,
    a {
        font-size: 1.1rem;
        
        @media (min-width: ${screenSize.desktopSize}) {
            font-size: 1.25rem
        }
    }

    button,
    a {
        cursor: pointer;
    }
`

export {
    GlobalStyling
}