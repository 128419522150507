import React from 'react'
import { DetailsStyling } from './style'

const OrderDetails = () => {
  return (
    <DetailsStyling>
      <p className="details__title">Details</p>
      <div className="details__detail">
        <p>Name resetaurant</p>
      </div>
      <div className="status">
        <p>Current status:</p>
        <span className="status__status">Ordered</span>
      </div>
    </DetailsStyling>
  )
}

export default OrderDetails
