import { color, borderRadius, screenSize } from "../../../style/constants";
import styled from "styled-components";

const ProductDetailStyle = styled.div`
    margin-bottom: 4rem;
    margin-top: 3rem;
`

const ProductDetailInfo = styled.section`
    margin-bottom: 4rem;

    h2,
    p {
        margin-bottom: 1rem;
    }

    a {
        text-decoration: underline;
        transition: 0.2s ease;

        &:hover {
            color: ${color.mainOrange};
        }
    }

    @media (min-width: ${screenSize.tabletSize}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`

const ProductDetailInfoContainer = styled.div`
    @media (min-width: ${screenSize.tabletSize}) {
        width: 56%;
    }

`

const ProductDetailImageContainer = styled.div`
    width: 100%;
    height: 20rem;
    margin-top: 1rem;

    @media (min-width: ${screenSize.tabletSize}) {
        width: 40%;
        height: 25rem;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        border-radius: ${borderRadius.mediumRadius};
    }
`

const OrderCardContainer = styled.section`
    h2 {
        background-color: ${color.mainBlue};
        border-radius: ${borderRadius.smallRadius};
        color: ${color.mainWhite};
        text-align: center;
        padding: 0.5rem 0;
        margin-bottom: 2rem;
    }
`

export {
    ProductDetailStyle,
    ProductDetailInfo,
    ProductDetailImageContainer,
    ProductDetailInfoContainer,
    OrderCardContainer
}