import { color, borderRadius, screenSize } from "../../../style/constants";
import styled from "styled-components";

const HeaderTitle = styled.h3`
  background: ${color.secondaryBlue};
  color: ${color.mainWhite};
  padding: 1rem;
  margin-bottom: 2rem;
`;

const CardStyling = styled.div`
  color: ${color.secondaryBlue};
  margin-bottom: 1.5rem;

  @media (min-width: ${screenSize.tabletSize}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ul {
    .li-mealsize {
      width: 5rem;
    }

    p {
      font-weight: bold;
    }

    .li-mealsize {
      /* background: red; */
      /* max-width: 5rem; */
    }

    input {
      width: 3rem;
      height: 2rem;
      border-radius: ${borderRadius.smallRadius};
      border: 1px solid ${color.mainBlue};
      text-align: center;
    }
  }
`;

const EuroSign = styled.span`
  margin-right: 1rem;
`;

const CardTopStyling = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: ${screenSize.tabletSize}) {
    width: 90%;
  }
`;

const Button = styled.button`
  font-size: 1.5rem;
  background: none;
  border: none;
  text-align: center;
`;

const Quantity = styled.span`
  font-size: 1.5rem;
`;

const AddButton = styled.button`
  background: ${color.mainBlue};
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  border: none;
  transition: 0.2s ease;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${color.secondaryBlue};
  }

  img {
    width: 1rem;
  }
`;

const AvailableProductStyling = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 2rem;
  }
`;

export { HeaderTitle, CardStyling, Button, Quantity, AddButton, AvailableProductStyling, CardTopStyling, EuroSign };