import React from 'react'
import AccountOverviewTemplate from './AccountOverviewTemplate'

import img from '../../assets/img/restaurant.png';

const AccountOverview = () => {
  return (
    <>
      <AccountOverviewTemplate
      profileImage={img}
      companyName="Company name"
        description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
      street="stationstraat"
      houseNr="1"
      city="Brussels"
      country="Belgium"
      tags={["tag1", "tag2", "tag3"]}
        overview="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit."
      />
    </>
  )
}

export default AccountOverview
