import { screenSize } from "../../../style/constants";
import styled from "styled-components";

const SearchPageStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 3rem;
  justify-content: space-between;

  @media (min-width: ${screenSize.tabletSize} ) {
    flex-direction: row;
}
`;

const RestaurantGrid = styled.div`
  @media (min-width: ${screenSize.tabletSize} ) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    width: 80%;
  }

  @media (min-width: ${screenSize.desktopSize}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1520px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const FiltersStyle = styled.div`
  /* background: red; */
  margin-bottom: 3rem;

  @media (min-width: ${screenSize.tabletSize}) {
    width: 15%;
  }
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlaceholderWrap = styled.div`

input {
  margin-right: 0.5rem;
}
`;

export { SearchPageStyle, InputWrap, PlaceholderWrap, RestaurantGrid, FiltersStyle };