import styled from "styled-components";

const MobileNavigationStyle = styled.nav`
display: block;
cursor: pointer;
  @media (min-width: 750px) {
    display: none;
  }
`;

export { MobileNavigationStyle };