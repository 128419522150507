import React, { useState } from 'react'
import { SplashPageStyle, H1, InputStyle, SubmitButton, FormWrapper, InputWrapper } from './style'
import Icon from '../../assets/img/icons/search-icon.svg';
import * as Routes from '../../routes';
import { Link } from "react-router-dom";

const SplashPage = () => {

  const [inputValue, setInputValue] = useState()
  return (
    <SplashPageStyle>
      <H1>Find a restaurant near you!</H1>
      <div>
        <form action=''>
          <FormWrapper>
            <InputWrapper>
              <InputStyle onChange={(e) => setInputValue(e.target.value)} type="text" />
              {/* hier proberen een Link van te maken ipv een submit aangezien we de waarde niet kunnen meekrijgen in de url */}
              <SubmitButton to={`${Routes.SEARCH}?result=${inputValue}`}>
                <img src={Icon} alt="icon" />
              </SubmitButton>
            </InputWrapper>
          </FormWrapper>
        </form>
      </div>
    </SplashPageStyle >
  )
}

export default SplashPage