import React from 'react'
import { RestaurantCardStyle, RestaurantCardWrap, TagsWrap } from '../../pages/splash/style'
import { Link } from 'react-router-dom';
import Tag from '../../pages/splash/Tag';

const RestaurantCard = (props) => {
  return (
    <RestaurantCardStyle>
      <Link to={`/pages/productDetail/${props.id}`}>
        <img src={props.bgimage} alt="restaurant" />
        <RestaurantCardWrap>
          <h3>{props.restaurantname}</h3>
          <span>{props.score}</span>
        </RestaurantCardWrap>
        <p>{props.mealcount} meals available</p>
        <TagsWrap>
          <Tag tagname="test" />
          <Tag tagname="test" />
          <Tag tagname="test" />
        </TagsWrap>
      </Link>
    </RestaurantCardStyle>
  )
}

export default RestaurantCard
