import React from 'react'
import RestaurantCard from '../../components/restaurantCard/RestaurantCard'
import { BaseLayout } from '../../layouts'
import Filters from './Filters'
import { SearchPageStyle, RestaurantGrid } from './style'
import { GET_RESTAURANTS } from '../../graphql/restaurants'
import { useQuery } from '@apollo/client';

const SearchPage = () => {
  const { loading, error, data } = useQuery(GET_RESTAURANTS);

  // clean code
  if (loading) { return (<h2>LOADING......</h2>) }
  if (error) { return 'Something went wrong...' }

  console.log(data)

  return (
    <BaseLayout>
      <SearchPageStyle>
        {/* filters */}
        <Filters />
        {/* restaurants */}
        <RestaurantGrid>
          {
            data.getRestaurants.map((e) => {
              return (
                <RestaurantCard
                  id={e.id}
                  bgimage='https://picsum.photos/1920/1080'
                  restaurantname={e.name}
                  score={7.5}
                  mealcount={5}
                />
              )
            })
          }
        </RestaurantGrid>
      </SearchPageStyle>
    </BaseLayout>
  )
}

export default SearchPage
