import React from 'react';
import { BaseLayout } from '../layouts';
import AppAd from './splash/AppAd';
import Steps from './splash/Steps';
import Suggestions from './splash/Suggestions';
import SplashPage from './splash/SplashPage';

const HomePage = () => {

  return (
    <BaseLayout>
      {/* <SplashPage /> */}
      <Steps />
      <Suggestions />
      <AppAd />
    </BaseLayout>
  )
}

export default HomePage;
