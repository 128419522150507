import React from 'react'
import ProfileNavigation from '../../components/profileNavigation/ProfileNavigation'
import { BaseLayout } from '../../layouts'
import { AccountOverviewContent, AccountOverviewStyle, ImageInfoWap, OverviewStyling, StyledTag, Wrapped } from './style'
import pinIcon from '../../assets/img/icons/pin.svg';

const AccountOverviewTemplate = (props) => {
  return (
    <BaseLayout>
      <AccountOverviewStyle>
        <ProfileNavigation />
        <AccountOverviewContent>
          <ImageInfoWap>
            <img className="restaurantProfilepic" src={props.profileImage} alt="restaurant" />
            <div>
              <h2 className="margin-top">{props.companyName}</h2>
              <p className="maxwidth">{props.description}</p>
              <Wrapped>
                <img src={pinIcon} alt="pin icon" />
                <div>
                  <p>{props.street} {props.houseNr}</p>
                  <p>{props.city} {props.country}</p>
                </div>
              </Wrapped>
              <div>
                {
                  props.tags.map((tag, i) => {
                    return <StyledTag key={i}>{tag}</StyledTag>
                  })
                }
              </div>
            </div>
          </ImageInfoWap>
          <OverviewStyling>
            <h2>Overview</h2>
            <p className="maxwidth">{props.overview}</p>
          </OverviewStyling>
        </AccountOverviewContent>
      </AccountOverviewStyle>
    </BaseLayout>
  )
}

export default AccountOverviewTemplate
