import { gql } from '@apollo/client';

export const GET_RESTAURANTS = gql`
query {
    getRestaurants {
      id,
      name
    }
  }
`;

export const GET_PRODUCTS_FROM_SPECIFIC_RESTAURANT = gql`
 query {
  getRestaurant( id: 1 )  {
    id
    name
    products {
      id
      product {name}
    }
  }
}
`;

export const GET_RESTAURANTS_AND_ORDERS = gql`
  query {
    getRestaurants {
      id,
      name
      description
      image
      products {
        id
        amount
        price
        product {name type}
    	}
    }
  }
`;