import React from 'react'
import { BaseLayout } from '../../layouts'
import Order from './Order'
import OrderDetails from './OrderDetails'
import { AccountOrderStyling } from './style'


const AccountOrders = (props) => {

  return (
    <BaseLayout>
      <h1>Orders</h1>
      <AccountOrderStyling>
        <Order type="meals" />
        {/* <Order type="drinks" /> */}
        <OrderDetails />
      </AccountOrderStyling>

    </BaseLayout>
  )
}

export default AccountOrders
