import styled from "styled-components";
import { borderRadius, color, screenSize } from "../../../style/constants";

const CheckoutStyled = styled.div`
    margin-bottom: 4rem;
    
    h2 {
        margin-top: 3rem;
        margin-bottom: 2rem;
    }
`

const StyledTitle = styled.h3`
    background-color: ${color.secondaryBlue};
    border-radius: ${borderRadius.smallRadius};
    color: ${color.mainWhite};
    text-align: center;
    padding: 0.5rem 0;
    margin-bottom: 2rem;
`

const StyledBasket = styled.section`
    margin-bottom: 3rem;

    .basketOrder {
      background: ${color.tertiaryBlue};
      color: ${color.mainWhite};
      font-size: 1.25rem;
      margin: 1rem 0;
      padding: 1rem ;
<<<<<<< HEAD

=======
      border-radius: ${borderRadius.smallRadius};
      
>>>>>>> b29699adc554900655bedd99e0bd443b41034384
      display: flex;
      justify-content: space-between;
    }

   
`

const StyledPrice = styled.div`
    background-color: ${color.secondaryBlue};
    color: ${color.mainWhite};
    border-radius: ${borderRadius.smallRadius};
    padding: 1rem 1rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
`

const StyledFormDiv = styled.div`
    input,
    label {
        display: block;
    }
`

const FormFlex = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div {
        width: 70%;

        &:last-child {
            width: 20%;
        }

        input {
            max-width: 100%;
        }
    }
`

const StyledForm = styled.form`
    input {
        width: 100%;
    }
`

const CheckoutFlex = styled.div`
    @media (min-width: ${screenSize.tabletSize}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row-reverse;

        form {
            width: 40%
        }

        section {
            width: 30%;
        }
    }
`

const SelectForm = styled.select`
    padding: 0.7rem;
    border: none;
    margin-bottom: 1rem;
    border-radius: ${borderRadius.smallRadius};
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    width: 100%;
`

export {
  CheckoutStyled,
  StyledTitle,
  StyledBasket,
  StyledPrice,
  StyledFormDiv,
  FormFlex,
  StyledForm,
  CheckoutFlex,
  SelectForm
}