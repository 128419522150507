import { color, borderRadius, screenSize } from "../../../style/constants";
import styled from "styled-components";

const AccountOverviewStyle = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screenSize.desktopSize}) {
    flex-direction: row;
  }

  .margin-top {
    margin-top: 2rem;
  }

  .restaurantProfilepic {
    width: 100%;
  }

  p {
    margin: 1rem 0;
  }

  .maxwidth {
    @media (min-width: ${screenSize.desktopSize}) {
      max-width: 50%;
    }
  }

`;

const Wrapped = styled.div`
  margin-bottom: 1rem;

  display: flex;
  align-items: center;

  p {
    color: ${color.mainBlue};
    margin: 0;
    margin-left: 1rem;
  }
`;

const StyledTag = styled.span`
  background: ${color.mainBlue};
  color: ${color.mainWhite};
  border-radius: ${borderRadius.smallRadius};
  padding: 0.4rem 1.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 1.25rem;
`;

const OverviewStyling = styled.div`
  margin: 2rem 0;
  
  h2 {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${color.mainBlue};
  }
`;

const ImageInfoWap = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screenSize.desktopSize}) {
    flex-direction: row;

    .restaurantProfilepic {
      width: 100%;
      max-width: 30rem;
      margin-right: 1rem;
    }
  }
`;

const AccountOverviewContent = styled.div`
  @media (min-width: ${screenSize.desktopSize}) {
    padding-left: 1rem;
    border-left: 1px solid black;
    margin-bottom: 4rem;
  }
`;

export { AccountOverviewStyle, Wrapped, StyledTag, OverviewStyling, ImageInfoWap, AccountOverviewContent };