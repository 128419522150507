import { color, screenSize } from "../../../style/constants";
import styled from "styled-components";

const AccountOrderStyling = styled.div`
  display: flex;
  flex-direction: column;
 
  @media (min-width: ${screenSize.desktopSize}) {
    flex-direction: row;
    align-items: flex-end;
    gap: 0 1rem;
  }
`;

const OrderStyling = styled.div`
  flex: 1;

  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

`;

const OrderContent = styled.div`


.meals {

  .mealsTitleWrap {
    display: flex;
    justify-content: space-between;

    p {
      background: ${color.secondaryBlue};
      color: ${color.mainWhite};
      width: 100%;
      padding: 1rem 0;

      :nth-child(2) {
        text-align: center;
      }
      :nth-child(3) {
        text-align: right;
      }
    }
  }

  .mealsOrder {
    margin: 1rem 0;

    display: flex;
    justify-content: space-between;

    p {
      width: 100%;

      :nth-child(2) {
        text-align: center;
      }
      :nth-child(3) {
        text-align: right;
      }
    }
  }

  
}
`;

const TotalPrice = styled.div`
  background: ${color.secondaryBlue};
  color: ${color.mainWhite};
  font-weight: bold;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
`;


const DetailsStyling = styled.div`
  flex: 1;

  .details__title {
   background:${color.secondaryBlue} ;
   color: ${color.mainWhite};
   margin-top: 0.5rem;
   padding: 1rem 0;
  }

  .details__detail {
     padding-top: 4rem;
   }

  .status {
    background: ${color.secondaryBlue};
    color: ${color.mainWhite};
    font-weight: bold;
    padding: 1rem 0;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
`;

export { AccountOrderStyling, OrderStyling, OrderContent, TotalPrice, DetailsStyling };