import React from 'react'
import logo1 from '../../assets/img/about/coca_cola_logo_icon512.png'
import { ClientStyling, ClientUl } from './style'

const Clients = () => {
    return (
        <ClientStyling>
            <h3>Meet our clients</h3>
            <ClientUl>
                <li>
                    <img src={logo1} alt='client'/>
                </li>
                <li>
                    <img src={logo1} alt='client'/>
                </li>
                <li>
                    <img src={logo1} alt='client'/>
                </li>
                <li>
                    <img src={logo1} alt='client'/>
                </li>
                <li>
                    <img src={logo1} alt='client'/>
                </li>
                <li>
                    <img src={logo1} alt='client'/>
                </li>
                <li>
                    <img src={logo1} alt='client'/>
                </li>
            </ClientUl>
        </ClientStyling>
    )
}

export default Clients
