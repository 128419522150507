import React, { useContext, useEffect, useState } from 'react'
import { Button, CardStyling, Quantity, AddButton, CardTopStyling, EuroSign } from './style'
import addButton from '../../assets/img/icons/addButton.svg';
import { MealsContext } from './RestaurantAvailableContext'


const MealCard = (props) => {


  const [quantity, setQuantity] = useState(0);
  const [priceMeal, setPriceMeal] = useState(0)
  const [meals, setMeals] = useContext(MealsContext);

  const increase = () => {
    setQuantity(quantity + 1);
  }

  const decrease = () => {
    setQuantity(quantity - 1);
    if (quantity <= 0) { setQuantity(0) }

  }
  return (
    <CardStyling>
      <CardTopStyling>
        <li className="li-mealsize"><p>{props.mealSize}</p></li>
        <li><EuroSign>€</EuroSign><input value={!isNaN(priceMeal) ? priceMeal : 0} type="text" placeholder="10" onChange={(e) => setPriceMeal(parseInt(e.target.value))} /></li>
        <li><Button onClick={decrease}>-</Button> <Quantity>{quantity}</Quantity> <Button onClick={increase}>+</Button></li>
      </CardTopStyling>
      <ul>
        <li>
          <AddButton
            onClick={
              () => setMeals(meals => [...meals, { meal: props.mealSize, price: priceMeal, amount: quantity }])
            }>
            <img src={addButton} alt="add" />
          </AddButton>
        </li>
      </ul>
    </CardStyling>
  )
}

export default MealCard
