import styled from "styled-components";
import { borderRadius, color } from "../../../style/constants";

const ShoppingCartLayout = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 2rem;
  }
  
  .meal {
    background: white;
    color: ${color.secondaryBlue};
    border-radius: ${borderRadius.smallRadius};
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 1rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
    font-family: sans-serif;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__mealamount {
      text-align: center;
      /* border-radius: 3px; */
      border: none;
      padding: 0.3rem 0;

      ::-webkit-inner-spin-button {
        opacity: 1;
      }
    }

    &__mealname {
      width: 9rem;
    }

    &__mealprice {
      font-weight: normal;
    }
  }
`;

export { ShoppingCartLayout };