import styled from "styled-components";
import { borderRadius, color, screenSize } from "../../../style/constants";

const OrderCardStyle = styled.div`
    background-color: white;
    color: ${color.secondaryBlue};
    border-radius: ${borderRadius.smallRadius};
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    form {
        width: 3rem;
        max-width: 33%;

        input {
            width: 100%;
        }
    }

    span {
        font-weight: bold;
    }

    h3 {
        width: 6rem;
    }

    p {
        width: 6rem;
    }
`

const OrderNameAndPortion = styled.div`
    @media (min-width: ${screenSize.tabletSize}) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        
        width: 40%;
    }
`

const AmountInput = styled.input`
    border: solid 1px black;
    border-radius: ${borderRadius.smallRadius};
    padding: 0.15rem 0;
    background-color: ${color.mainWhite};
`;

const AmountButton = styled.button`
    background-color: ${color.mainBlue};
    transition: 0.2s ease;
    padding: 0.25rem 0.50rem;
    color: ${color.mainWhite};
    border-radius: ${borderRadius.mediumRadius};
    border: none;
    font-weight: bold;

    &:hover {
        background-color: ${color.secondaryBlue};
    }
`;


export {
    OrderCardStyle,
    OrderNameAndPortion,
    AmountInput,
    AmountButton
}