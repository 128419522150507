import React from 'react';
import { Link } from 'react-router-dom';
import * as Routes from '../../../routes';

const FooterLegal = () => {
    return (
        <div>
            <h3>Legal</h3>
            <ul>
                <li>
                    <Link to={Routes.LANDING}>Customer Service</Link>
                </li>

                <li>
                    <Link to={Routes.LANDING}>Terms of Use</Link>
                </li>

                <li>
                    <Link to={Routes.LANDING}>Cookie Statement</Link>
                </li>

                <li>
                    <Link to={Routes.LANDING}>Privacy Statement</Link>
                </li>

                <li>
                    <Link to={Routes.FAQ}>FAQ</Link>
                </li>
            </ul>
        </div>
    )
}

export default FooterLegal;
