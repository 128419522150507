import React from 'react'
import { H3, Table, TotalTr } from './style'

const OrderTemplate = () => {
  return (
    <div>
      <H3>Order #1</H3>
      <Table>
        <thead>
          <tr>
            <th>Meals</th>
            <th>Qty</th>
            <th>Price</th>
          </tr>
          <tr>
            <td>Small meal</td>
            <td>2</td>
            <td>€ 10</td>
          </tr>
          <tr>
            <td>Medium meal</td>
            <td>2</td>
            <td>€ 10</td>
          </tr>

          <tr>
            <th>drinks</th>
            <th>Qty</th>
            <th>Price</th>
          </tr>
          <tr>
            <td>Small meal</td>
            <td>2</td>
            <td>€ 10</td>
          </tr>
          <tr>
            <td>Medium meal</td>
            <td>2</td>
            <td>€ 10</td>
          </tr>
          <TotalTr>
            <td>Total</td>
            <td></td>
            <td>€ 40</td>
          </TotalTr>
        </thead>
      </Table>
    </div>
  )
}

export default OrderTemplate
