import { useState, createContext } from "react";

export const OrdersContext = createContext();

export const OrdersProvider = (props) => {

  const [orders, setOrders] = useState([]);

  return (
    <OrdersContext.Provider value={[orders, setOrders]}>
      {props.children}
    </OrdersContext.Provider>
  );
}