import styled from "styled-components";
import { defaultPadding, screenSize } from "../../style/constants";

const Wrapper = styled.main`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;

    padding-left: ${defaultPadding.mobilePadding};
    padding-right: ${defaultPadding.mobilePadding};

    @media (min-width: ${screenSize.tabletSize}) {
        padding-left: ${defaultPadding.tabletPadding};
        padding-right: ${defaultPadding.tabletPadding};
    }

    @media (min-width: ${screenSize.desktopSize}) {
        padding-left: ${defaultPadding.desktopPadding};
        padding-right: ${defaultPadding.desktopPadding};
    }
`;

const InnerWrapper = styled.div`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;

    padding-left: ${defaultPadding.mobilePadding};
    padding-right: ${defaultPadding.mobilePadding};

    @media (min-width: ${screenSize.tabletSize}) {
        padding-left: ${defaultPadding.tabletPadding};
        padding-right: ${defaultPadding.tabletPadding};
    }

    @media (min-width: ${screenSize.desktopSize}) {
        padding-left: ${defaultPadding.desktopPadding};
        padding-right: ${defaultPadding.desktopPadding};
    }
`;

const SecondaryWrapper = styled.div`
    max-width: 1700px;
    width: 100%;
    margin: 0 auto;
    padding-left: ${defaultPadding.mobilePadding};
    padding-right: ${defaultPadding.mobilePadding};

    @media (min-width: ${screenSize.tabletSize}) {
        padding-left: ${defaultPadding.tabletPadding};
        padding-right: ${defaultPadding.tabletPadding};
    }

    @media (min-width: ${screenSize.desktopSize}) {
        padding-left: ${defaultPadding.desktopPadding};
        padding-right: ${defaultPadding.desktopPadding};
    }
`

export {
    Wrapper,
    SecondaryWrapper,
    InnerWrapper
}