import { Link } from "react-router-dom";
import * as Routes from '../../../routes';
import { MainNavigationStyle, ShoppingAmount, ShoppingStyle } from "./style";
import { useContext, useState } from "react";
import { OrdersContext } from "../../../pages/productDetail/OrderDetailContext";
import shoppingCart from '../../../assets/img/icons/shoppingCart.svg';

const MainNavigation = () => {

  const [orders, setorders] = useContext(OrdersContext);

  return (
    <MainNavigationStyle>
      <li>
        <Link to={Routes.LANDING}>Home</Link>
      </li>
      <li>
        <Link to={Routes.ABOUT}>About us</Link>
      </li>
      <li>
        <Link to={Routes.CONTACT}>Contact</Link>
      </li>
      <li>
        <Link to={Routes.LOGIN}>Log in</Link>
      </li>
      <ShoppingStyle>
        <Link to={Routes.SHOPPINGCART}><img src={shoppingCart} alt="Shopping cart" /><ShoppingAmount>{orders.length}</ShoppingAmount></Link>
      </ShoppingStyle>
    </MainNavigationStyle>
  );
};

export default MainNavigation;