import React from 'react'
import Status from './Status'
import { HeaderDetails, P } from './style'

const Details = () => {
  return (
    <div>
      <HeaderDetails>Details</HeaderDetails>
      <div>
        <P>time</P>
        <P>name</P>
        <P>phone</P>
        <P>email</P>
        <P>date order</P>
      </div>
      <Status />
    </div>
  )
}

export default Details
