import React, { useContext, useState } from 'react'
import { OrdersContext } from '../../pages/productDetail/OrderDetailContext';
import { OrderCardStyle, OrderNameAndPortion, AmountInput, AmountButton } from './style';

const OrderCard = (props) => {


  const [orders, setOrders] = useContext(OrdersContext);

  // setMeals(meals => [...meals, { meal: props.mealSize, price: priceMeal, amount: quantity }])

  return (
    <OrderCardStyle>
      <OrderNameAndPortion>
        <h3>{props.mealName}</h3>
        <p>{props.portions} {props.portions === 1 ? 'portion' : 'portions'}</p>
      </OrderNameAndPortion>

      <span>€{props.price}</span>



      <form
        onChange={(e) =>
          console.log(`meal: ${props.mealName} || portions: ${e.target.value} || price: ${props.price}`)
        }
      >
        <AmountInput className="portions" type="number" min={0} max={props.portions} />
        <input className="mealNameHidden" type="hidden" value={props.mealName} />
        <input className="priceHidden" type="hidden" value={props.price} />
      </form>
      <AmountButton onClick={
        () => setOrders(orders => [...orders, { meal: props.mealName, price: props.price, amount: props.portions }])
      }>add</AmountButton>
    </OrderCardStyle>
  )
}

export default OrderCard
